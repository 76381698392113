import { getFunctions, httpsCallable } from 'firebase/functions';

const getCompletion = async messages => {
  const chatResponse = httpsCallable(getFunctions(), 'chatResponse');
  const response = await chatResponse({
    messages,
    model: 'gpt-4o'
  });

  return response.data.content;
};

const getSpeech = async text => {
  const textToSpeech = httpsCallable(getFunctions(), 'textToSpeech');
  const response = await textToSpeech({ text });
  return response.data;
};

export { getCompletion, getSpeech };
