import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Button, Stack, Box } from '@mui/joy';
import { Info, Hearing, FactCheck, PlayCircle } from '@mui/icons-material';

import '../modules/firebase';
import { getCompletion, getSpeech } from '../modules/openai';

const systemDefn = {
  role: 'system',
  content:
    'You are friendly and an expert on all animals. Your target audience are young children. State the fact without prefixing them with words like sure or absolutely. But feel free to make the fact fun!'
};

const initialLoadingState = {
  name: false,
  sounds: false,
  facts: false
};

const Animal = ({ animal, getNewAnimal, viewportHeight }) => {
  const [messages, setMessages] = useState([systemDefn]);
  const [touchStart, setTouchStart] = useState(null);
  const [fetchedFact, setFetchedFact] = useState('');
  const [loadingState, setLoadingState] = useState(initialLoadingState);
  const audioRef = useRef(new Audio());
  const [orientation, setOrientation] = useState(
    window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
  );

  const animalRef = useRef(animal);

  const resetState = useCallback(() => {
    setMessages([systemDefn]);
    setFetchedFact('');
    setLoadingState(initialLoadingState);
    audioRef.current.pause();
  }, []);

  const handleTouchMove = useCallback(
    event => {
      const touchEndY = event.changedTouches[0].clientY;
      const touchStartY = touchStart ? touchStart.y : touchEndY;
      const dy = touchEndY - touchStartY;

      if (
        Math.abs(dy) >
          Math.abs(
            event.changedTouches[0].clientX - (touchStart ? touchStart.x : 0)
          ) &&
        dy > 0
      ) {
        event.preventDefault();
      }
    },
    [touchStart]
  );

  useEffect(() => {
    const handleResize = debounce(() => {
      setOrientation(
        window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
      );
    }, 150);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleTouchMove]);

  useEffect(() => {
    animalRef.current = animal;
    resetState();
  }, [animal, resetState]);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
    };
  }, []);

  const backgroundImage = useMemo(
    () =>
      orientation === 'landscape'
        ? `url(https://storage.googleapis.com/giggles-animals/photos/landscape/${animal}.jpeg)`
        : `url(https://storage.googleapis.com/giggles-animals/photos/${animal}.jpg)`,
    [animal, orientation]
  );

  const handleTouchStart = useCallback(event => {
    const touch = event.touches[0];
    setTouchStart({ x: touch.clientX, y: touch.clientY });
  }, []);

  const handleTouchEnd = useCallback(
    event => {
      if (!touchStart) return;

      event.preventDefault();

      const touchEnd = {
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY
      };
      const dx = touchEnd.x - touchStart.x;
      const dy = touchEnd.y - touchStart.y;

      if (Math.abs(dx) > Math.abs(dy)) {
        if (dx > 30) {
          getNewAnimal(false);
        } else if (dx < -30) {
          getNewAnimal(true);
        }
      } else if (Math.abs(dx) < 10 && Math.abs(dy) < 10) {
        if (touchStart.x < window.innerWidth / 2) {
          getNewAnimal(false);
        } else {
          getNewAnimal(true);
        }
      }

      setTouchStart(null);
    },
    [touchStart, getNewAnimal]
  );

  const speakText = useCallback(async base64Audio => {
    try {
      audioRef.current.pause();
      audioRef.current = new Audio(`data:audio/mp3;base64,${base64Audio}`);
      audioRef.current.onended = () => setLoadingState(initialLoadingState);
      await audioRef.current.play();
    } catch (error) {
      console.error(error);
      alert('🥺 something went wrong. Please try again 🙏');
      setLoadingState(initialLoadingState);
    }
  }, []);

  const playAnimalSound = useCallback(async () => {
    setLoadingState(prevState => ({ ...prevState, sounds: true }));
    try {
      audioRef.current.pause();
      audioRef.current = new Audio(
        `https://storage.googleapis.com/giggles-animals/sounds/${animal}.mp3`
      );
      audioRef.current.onended = () => setLoadingState(initialLoadingState);
      await audioRef.current.play();
    } catch (error) {
      console.error(error);
      alert('🥺 something went wrong. Please try again 🙏');
      setLoadingState(initialLoadingState);
    }
  }, [animal]);

  const sayAnimalName = useCallback(async () => {
    setLoadingState(prevState => ({ ...prevState, name: true }));
    try {
      const base64Audio = await getSpeech(animal);
      await speakText(base64Audio);
    } catch (error) {
      console.error(error);
      alert('🥺 something went wrong. Please try again 🙏');
      setLoadingState(prevState => ({ ...prevState, name: false }));
    }
  }, [animal, speakText]);

  const fetchAnimalFact = useCallback(async () => {
    setLoadingState(prevState => ({ ...prevState, facts: true }));
    try {
      const newFact = {
        role: 'user',
        content: `Tell me a ${
          messages.length > 1 ? 'new ' : ''
        }fact about ${animal}.`
      };
      const response = await getCompletion([...messages, newFact]);
      if (('fact', animalRef.current === animal)) {
        setMessages(prevMessages => [
          ...prevMessages,
          newFact,
          { role: 'assistant', content: response }
        ]);

        const base64Audio = await getSpeech(response);
        if (animalRef.current === animal) {
          setFetchedFact(base64Audio);
        }
      }
    } catch (error) {
      console.error(error);
      alert('🥺 something went wrong. Please try again 🙏');
    } finally {
      setLoadingState(prevState => ({ ...prevState, facts: false }));
    }
  }, [animal, messages]);

  const playFetchedFact = useCallback(async () => {
    setLoadingState(prevState => ({ ...prevState, facts: true }));
    try {
      if (fetchedFact) {
        await speakText(fetchedFact);
        setFetchedFact('');
      }
    } catch (error) {
      console.error(error);
      alert('🥺 something went wrong. Please try again 🙏');
    }
  }, [fetchedFact, speakText]);

  const handleClick = useCallback(
    event => {
      const clickX = event.clientX;
      const halfWidth = window.innerWidth / 2;

      if (clickX < halfWidth) {
        getNewAnimal(false);
      } else {
        getNewAnimal(true);
      }
    },
    [getNewAnimal]
  );

  // console.log(loadingState, fetchedFact, messages);

  return (
    <Box
      sx={{
        backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: `${viewportHeight}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingBottom: '22px'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '80%',
          zIndex: 1,
          cursor: 'pointer'
        }}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onClick={handleClick}
        onTouchMove={handleTouchMove}
      />
      <Stack spacing={3} alignItems="center">
        <Button
          size="lg"
          startDecorator={<Info />}
          onClick={event => {
            setLoadingState(initialLoadingState);
            sayAnimalName();
          }}
          loading={loadingState.name}
        >
          {animal}
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '22px' }}>
          <Button
            startDecorator={<Hearing />}
            size="lg"
            onClick={event => {
              setLoadingState(initialLoadingState);
              playAnimalSound();
            }}
            loading={loadingState.sounds}
          >
            sounds
          </Button>
          {fetchedFact ? (
            <Button
              startDecorator={<PlayCircle />}
              size="lg"
              disabled={loadingState.facts}
              onClick={event => {
                setLoadingState(initialLoadingState);
                playFetchedFact();
              }}
              loading={loadingState.facts}
            >
              play fact
            </Button>
          ) : (
            <Button
              startDecorator={<FactCheck />}
              size="lg"
              onClick={event => {
                fetchAnimalFact();
              }}
              loading={loadingState.facts}
            >
              fetch a fact
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default Animal;

// Utility function to debounce a function
function debounce(fn, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), delay);
  };
}
