import { useState, useEffect } from 'react';
import { LinearProgress, CssBaseline, Box } from '@mui/joy';
import { getDatabase, ref, child, get } from 'firebase/database';

import Animal from './Animal';
import Welcome from './Welcome';
import '../modules/firebase';

export default function App() {
  const [animals, setAnimals] = useState();
  const [animal, setAnimal] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  useEffect(() => {
    const handleResize = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const loadAnimals = async () => {
      const dbRef = ref(getDatabase());
      const snapshot = await get(child(dbRef, 'animals'));

      const shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      };

      setAnimals(shuffleArray(snapshot.val()));
    };

    loadAnimals();

    const showWelcome = localStorage.getItem('showWelcomeModal') !== 'false';
    setShowWelcomeModal(showWelcome);
  }, []);

  const getNewAnimal = previous => {
    let newAnimalIndex;

    if (previous) {
      newAnimalIndex = animal === 0 ? animals.length - 1 : animal - 1;
    } else {
      newAnimalIndex = animal === animals.length - 1 ? 0 : animal + 1;
    }

    setAnimal(newAnimalIndex);
  };

  if (!animals) {
    return <LinearProgress style={{ marginVertical: 10 }} />;
  }

  return (
    <Box sx={{ height: `${viewportHeight}px` }}>
      <CssBaseline />
      <Welcome
        open={showWelcomeModal}
        onClose={() => setShowWelcomeModal(false)}
      />
      <Animal
        animal={animals[animal]}
        getNewAnimal={getNewAnimal}
        viewportHeight={viewportHeight}
      />
    </Box>
  );
}
