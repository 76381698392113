import { useState } from 'react';
import {
  Modal,
  Typography,
  Checkbox,
  Button,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/joy';
import { EmojiNature } from '@mui/icons-material';

const Welcome = ({ open, onClose }) => {
  const [showAgain, setShowAgain] = useState(true);

  const handleCheckboxChange = event => {
    setShowAgain(!event.target.checked);
  };

  const handleClose = () => {
    if (!showAgain) {
      localStorage.setItem('showWelcomeModal', 'false');
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog>
        <DialogTitle>Welcome to Giggles AI!</DialogTitle>
        <DialogContent>
          <Typography gutterBottom fontStyle="italic">
            Here are some tips to help you get started:
          </Typography>
          <Typography gutterBottom>
            To explore different animals, swipe left or right on your phone or
            touch screen. On other devices, click on the left side of the animal
            to go back or the right side to go forward.
          </Typography>
          <Typography gutterBottom>
            Tap "fetch a fact" to generate a new, original fact each time. If
            you stay on the same animal and keep tapping "fetch a fact," you'll
            learn a variety of unique facts about that animal. It's a paw-some
            way to discover more!
          </Typography>
          <Typography endDecorator={<EmojiNature />}>
            Enjoy your adventure!
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Button sx={{ mt: 2 }} onClick={handleClose}>
            Continue
          </Button>
          <Checkbox
            checked={!showAgain}
            onChange={handleCheckboxChange}
            label="Don't show me this again"
          />
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default Welcome;
