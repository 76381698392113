import { initializeApp } from 'firebase/app';

initializeApp({
  apiKey: 'AIzaSyDBI9x0xvXgQpimGJdtJNwQ0pcFZQelVhc',
  authDomain: 'openai-1111.firebaseapp.com',
  projectId: 'openai-1111',
  storageBucket: 'openai-1111.appspot.com',
  messagingSenderId: '161322896136',
  appId: '1:161322896136:web:139fc313c5231aa63ebabd',
  measurementId: 'G-M9ZNJX9GMY',
  databaseURL: 'https://giggles-389708-default-rtdb.firebaseio.com/'
});
